

.contact-btn {
    position: sticky;
    bottom: 2%;
    left: 100%;
    width: 180px;
    height: 40px;
    margin-right: 20px;
    padding: 0;
    border: none;
    border-radius: 50px;
    background-color: rgb(0,122,255);
    font-weight: bold;
    color: white;

    transition: transform 0.3s ease-in-out;
}
.contact-btn:hover {
    transform: scale(1.1);
}
.contact-btn:active {
    transform: scale(1);
    transition: none;
}

.contact-btn p {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 20%;
    font-size: 1.1rem;
}

.letter-logo {
    position: absolute;
    right: 0;
    margin: 0 7%;
    top: 50%;
    transform: translateY(-50%);
    height: auto;
    width: 14%;
    filter: brightness(0) invert(1);
}