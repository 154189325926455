.navbar-container {
    position: absolute;
    margin: 25px auto auto auto;
    right: 10%;
    font-size: 25px;
}

.navbar-container ul {
    background-color: rgb(12,15,26);
    list-style-type: none;
}

.navbar-container li{
    display: inline;
}

.navbar-container ul li button {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    font-weight: bold;
    text-decoration: none;
    background: none;
    border: none;

    transition: color 0.5s;
    transition: filter 0.5s;
}

/*
START: CSS animation causes all anchors to turn grey except the hovered anchor tag.
*/
.navbar-container:hover .navbar-link {
    filter: blur(2px);
}

.navbar-container .navbar-link:hover {
    color: rgb(0,122,255);
    filter: none;
}
/*END*/