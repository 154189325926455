/*
styling sheet for the Home Page component.
*/

.portrait {
    position: absolute;
    top: 10%;
    left: 40%;
    width: 25%;
    height: auto;
}

.portrait img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.intro-container{
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    margin-left: 10%;
    width: 35%;
    height: auto;
}

.intro-title {
    color: white;
    font-weight: bold;
    font-size: 4rem;
}

.intro-description {
    position: relative;
    margin: 3.5% auto;
    color: white;
    font-size: 1.1rem;
}

.social-media-container {
    position: relative;
    left: 0;
    width: 100%;
    height: 60px;
    margin: 5% auto;
    list-style-type: none;
}

.social-media-container li {
    display: inline-block;
    width: 50px;
    padding: 0 7%;
    font-size: 3rem;
}

.media-icon {
    color: white;

    transition: all 0.3s ease-in-out;
}
.media-icon:hover {
    color: rgb(0,122,255);
    transform: scale(1.2);
}

.quick-info-table {
    position: absolute;
    width: 20%;
    top: 16%;
    right: 10%;
}

.quick-info-table tr {
    height: 100%;
    border-bottom: 0.2px solid rgb(213, 213, 213);
}

.quick-info-title {
    margin: 10% auto 10%;
    color: white;
    font-weight: bold;
}

.quick-info-description {
    margin: 0% 0% 15%;
    color: rgb(213, 213, 213);
    font-size: 1.1em;
}

.quick-info-link {
    position: relative;
    display: inline-block;
    bottom: 20px;
    padding: 5px;
    color: white;
    text-decoration: none;
    font-weight: bold;
    overflow: hidden;

    /* The css animation code for the blue swipe color transition. */
    background: linear-gradient(to right, rgb(0,122,255), rgb(0,122,255) 50%, white 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 0.7s ease;
}
.quick-info-link:hover {
    background-position: 0 100%;
}
/* END code for the vlue swipe color transition. */

.arrow-link {
    display: inline-block;
    margin-left: 10px;
    font-size: 25px;
    color: white;

    transition: transform 1s;
}

/*
.quick-info-link:hover .arrow-link {
    transform: translateX(30px);
}
*/

.circle-btn {
    position: absolute;
    left: 10%;
    bottom: 10%;
    width: 110px;
    height: 110px;
    border-radius: 100% !important;
    background-color: rgb(0,122,255);
}
.circle-btn:hover .down-arrow {
    transform: translateY(20px);
}
.down-arrow {
    font-size: 5rem;
}
@keyframes down-arrow {
    0%{
        -webkit-transform: translateY(0px);
    }
    50%{
        -webkit-transform: translateY(-20px);
    }
    80% {
        -webkit-transform: translateY(10px);
    }
    100% {
        -webkit-transform: translateY(0px);
    }
}
.down-arrow {
    animation: down-arrow 1.8s infinite;
}