
.info-container {
    position: relative;
    width: 60%;
    height: 50%;
    top: 25%;
    margin-left: auto;
    margin-right: auto;
}

.link-nav-container {
    border-bottom: solid white 1px;
    display: flex;
    justify-content: center;
}

.link-nav-container li {
    display: inline-block;
    padding: 0 15px 15px;

    transition: color 0.5s;
    transition: filter 0.5s;
}

.link-nav-container li button {
    background:none;
    border:none;
    font-size: 1.5rem;
    font-weight: bold;
    color: white;

    /* The css animation code for the blue swipe color transition. */
    background: linear-gradient(to right, rgb(0,122,255), rgb(0,122,255) 50%, white 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 0.7s ease;
}
.link-nav-container li button:hover {
    background-position: 0 100%;
}

.information-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    transition: all 0.7s ease;
}

.table-info-container {
    position: relative;
    width: 30%;
    height: 370px;
}

@keyframes fade-in {
    from {
        opacity: 0;
        -webkit-transform: translateY(10%);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0px);
    }
}

.tr-description {
    color: white;
}

.tr-description p {
    margin: 10% 5% 0;
    font-size: 1.1rem;
}

.tr-image {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 130px;
}

.tr-image img {
    object-fit: contain;
}

.table-info-container tr {
    display: flex;
    justify-content: center;
}

.about-container {
    position: absolute;
}

.about-title {
    position: absolute;
    margin-top: 3%;
    left: 10%;
}

.about-subtitle {
    color: white;
    font-weight: bold;
    font-size: 1.50rem;
}

.about-header {
    color: white;
    font-size: 2.85rem;
    font-weight: bold;
}